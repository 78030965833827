const initialState = {
  privacyPolicyModal: false,
  termsOfServiceModal: false,
  unauthorizedEmailCollectionModal: false,
  popUpModal: {
    0: {display: false, popUpId: undefined, image: '', name: ''},
    1: {display: false, popUpId: undefined, image: '', name: ''},
    2: {display: false, popUpId: undefined, image: '', name: ''},
    3: {display: false, popUpId: undefined, image: '', name: ''},
    4: {display: false, popUpId: undefined, image: '', name: ''},
  },
}

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PRIVACY_POLICY_ON': {
      return {...state, privacyPolicyModal: true}
    }
    case 'PRIVACY_POLICY_OFF': {
      return {...state, privacyPolicyModal: false}
    }
    case 'TERMS_OF_SERVICE_ON': {
      return {...state, termsOfServiceModal: true}
    }
    case 'TERMS_OF_SERVICE_OFF': {
      return {...state, termsOfServiceModal: false}
    }
    case 'UNAUTHORIZED_EMAIL_COLLECTION_ON': {
      return {...state, unauthorizedEmailCollectionModal: true}
    }
    case 'UNAUTHORIZED_EMAIL_COLLECTION_OFF': {
      return {...state, unauthorizedEmailCollectionModal: false}
    }
    case `POP_UP_MODAL_${action.id}_ON`: {
      return {
        ...state,
        popUpModal: {
          ...state.popUpModal,
          [action.id]: {display: true, popUpId: action.data.popUpId, url: action.data.image, name: action.data.name},
        },
      }
    }
    case `POP_UP_MODAL_${action.id}_OFF`: {
      return {
        ...state,
        popUpModal: {
          ...state.popUpModal,
          [action.id]: {display: false, popUpId: undefined, url: '', name: ''},
        },
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default ModalReducer
