const initialState = {
  menu: 'dangolPlus',
}

const CustomerMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_CUSTOMER_MENU': {
      return {...state, menu: action.menu}
    }
    default: {
      return {...state, menu: 'dangolPlus'}
    }
  }
}

export default CustomerMenuReducer
