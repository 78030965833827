const initialState = {
  menu: 'kiosk',
}

const MenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_UNTACT_MENU': {
      return {...state, menu: action.menu}
    }
    default: {
      return {...state, menu: 'kiosk'}
    }
  }
}

export default MenuReducer
