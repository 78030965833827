import {createGlobalStyle} from 'styled-components'
import styled from 'styled-components'
import icoCheckbox from 'assets/svg/kr/check-active.svg'
import icoCheckboxCircleBefore from 'assets/svg/kr/check-circle.svg'
import icoCheckboxCircle from 'assets/svg/kr/check-active-circle.svg'
import icoCheckboxLight from 'assets/svg/kr/check-active-light.svg'

export const GlobalStyle = createGlobalStyle`

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
  font-family: 'Noto Sans KR', sans-serif;
	vertical-align: baseline;
  box-sizing: border-box;
}

html {
font-size: 62.5%; // 1rem = 10px 로 변경 한 것, 바꾼 이유는 사파리에서 폰트가 너무 작은것은 허용하지 않기 때문.
// 참고링크 = https://stackoverflow.com/questions/68790660/setting-root-font-size-not-affecting-rem-units-in-safari-for-margin-padding-et
// 128px => 12.8rem , 4px => 0.4rem 가능!
}


/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    background-color:transparent; 
    cursor:pointer;
    box-sizing:border-box; 
    outline: 0;
    border:0;
}

input {box-sizing:border-box;}
input[type='checkbox'],input[type='radio'],select {appearance:none; -webkit-appearance:none; -moz-appearance:none; -o-appearance:none; outline: 0; box-sizing:border-box;}
select::-ms-expand {display:none;}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
`

// 기본 컨텐츠 영역 1200px
export const Inner = styled.div`
  width: 120rem;
  position: relative;
  margin: auto;
`

// 탭
export const TabBtnWrapStyled = styled.div`
  margin-bottom: 102px;
  ul {
    display: flex;
  }
  ul li + li {
    margin-left: 30px;
  }
`

// 탭 버튼
export const TabBtnStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 145px;
  border-radius: 30px;
  border: solid 1px ${props => (props.active ? '#68c9f3' : '#a4a4a4')};
  background-color: ${props => (props.active ? '#e4f7ff' : '#fff')};
  font-size: 1.6rem;
  color: ${props => (props.active ? '#00abeb' : '#a4a4a4')};
  height: 53px;
  cursor: pointer;
  :hover {
    border-color: #68c9f3;
    background-color: #e4f7ff;
    color: #00abeb;
  }
`
// 검색 영역
export const SearchStyled = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 70px;
  justify-content: space-between;
  > div:first-child {
    width: 277px;
  }
  .btn-sel {
    width: 100%;
  }
  > div:last-child {
    width: calc(100% - 307px);
  }
  .inp.inp-sch {
    width: 100%;
    height: 50px;
  }
`
export const SelectorStyled = styled.div`
  position: relative;
  .btn-sel {
    position: relative;
    height: 50px;
    padding: 0 20px;
    min-width: 100px;
    border-radius: 10px;
    border: solid 1.5px #e2e2e2;
    font-size: 16px;
    text-align: left;
    border-color: ${props => (props.active ? ' #00abeb' : '#e2e2e2')};
  }
  &.selected-yet .tit {
    color: #565656;
  }
  .btn-sel.has-err {
    border-color: #ff3831;
  }
  .btn-sel.type-sm {
    height: 40px;
  }
  .btn-sel.type-sm + .selector-list {
    top: 40px;
  }
  .ico {
    position: absolute;
    top: 50%;
    right: 17px;
    margin-top: -8px;
    width: 17px;
    height: 16px;
    transition: all 0.5s ease-in-out;
    transform: rotate(${props => (props.active ? '180deg' : '0')});
  }
  .selector-list {
    position: absolute;
    display: ${props => (props.active ? 'block' : 'none')};
    top: 51px;
    left: 0;
    right: 0;
    z-index: 50;
    padding: 24px 0 28px;
    box-shadow: 5px 5px 15px 3px rgba(0, 0, 0, 0.05);
    border: solid 1px #f2f2f2;
    background-color: #fff;
  }
  .selector-list li + li {
    margin-top: 15px;
  }
  .selector-list .btn {
    text-align: left;
    padding: 0 22px;
    width: 100%;
    font-size: 16px;
    line-height: 30px;
  }
`

// 인풋 스타일
export const InpStyled = styled.div`
  position: relative;

  .inp {
    font-family: 'Noto Sans KR', sans-serif;
    height: 56px;
    padding: 0 25px;
    min-width: 100px;
    border-radius: 10px;
    border: solid 1px #e2e2e2;
    font-size: 16px;
    box-sizing: border-box;
    color: #000;
    font-weight: 300;
  }
  .inp::placeholder {
    color: #565656;
  }
  .inp.inp-sm {
    height: 40px;
    padding: 0 20px;
    font-weight: 300;
    outline-color: #00abeb;
  }
  .inp.has-err {
    border-color: #ff3831;
  }
  .inp-sch {
    padding-left: 56px;
  }
  .ico-sch {
    position: absolute;
    top: 50%;
    left: 15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    color: #000;
  }
  .inp-sel + .ico-sel {
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -9px;
  }
  .inp-txtarea {
    padding: 20px 25px;
    font-size: 16px;
    width: 100%;
    min-height: 164px;
    resize: none;
  }
  .inp-file .txt {
    line-height: 56px;
    overflow: hidden;
    white-space: nowrap;
  }
  .inp-file-real {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .policy-box {
    height: 237px;
    padding: 20px 30px;
    border-radius: 10px;
    border: solid 1px #c0c0c0;
    overflow-y: scroll;
  }
  .policy-box p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: -0.8px;
    color: #000;
  }
  .checkbox {
    margin-right: 20px;
  }
  .inp-check-wrap {
    display: flex;
    align-items: center;
  }
  .chk-wrap {
    display: flex;
    align-items: center;
    position: relative;
  }
  .chk-wrap.two-line {
    align-items: flex-start;
  }
  .chk-wrap.two-line .inp-check {
    top: 3px;
  }

  .chk-wrap .inp-check {
    position: absolute;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .chk-wrap .inp-check:before {
    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    border: solid 2px #c0c0c0;
    box-sizing: border-box;
    content: '';
  }
  .chk-wrap .inp-check:checked:before {
    background: url(${icoCheckbox});
    border: 0;
  }
  .chk-wrap .txt {
    padding-left: 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: -0.8px;
    color: #565656;
  }
  .inp-check-wrap.type-circle .inp-check {
    width: 22px;
    height: 22px;
  }
  .inp-check-wrap.type-circle .inp-check:before {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: solid 2px #c0c0c0;
  }
  .inp-check-wrap.type-circle .inp-check:checked:before {
    border: 0;
    background: url(${icoCheckboxCircle});
  }
  .inp-check-wrap.type-circle .chk-wrap .txt {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.8px;
    color: #000;
  }

  .inp-check-wrap.type-circle2 .inp-check {
    width: 22px;
    height: 22px;
  }
  .inp-check-wrap.type-circle2 .inp-check:before {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: solid 2px #c0c0c0;
  }
  .inp-check-wrap.type-circle2 .inp-check:checked:before {
    border: 0;
    background: url(${icoCheckboxLight});
  }
  .inp-check-wrap.type-circle2 .chk-wrap .txt {
    padding-left: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.8px;
    color: #767676;
  }
  .inp-check-wrap.type-circle2 .inp-check:checked + .txt {
    color: #000;
  }

  .inp-check-wrap.type-circle3 .inp-check {
    width: 30px;
    height: 30px;
  }
  .inp-check-wrap.type-circle3 .inp-check:before {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: url(${icoCheckboxCircleBefore}) no-repeat 100% / 100%;
    border: 0;
  }
  .inp-check-wrap.type-circle3 .inp-check:checked:before {
    border: 0;
    background: url(${icoCheckboxLight}) no-repeat center / 28px 28px;
  }
  .inp-check-wrap.type-circle3 .chk-wrap .txt {
    padding-left: 45px;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.8px;
    color: #000;
  }
  .inp-check-wrap.type-circle3 .inp-check:checked + .txt {
    color: #000;
  }
  .readonly {
    cursor: not-allowed;
  }
`

//리스트
export const ListStyled = styled.div`
  margin-bottom: 90px;
  .tbl-th,
  .tbl-td {
    display: flex;
  }
  .tbl-th li {
    font-size: 16px;
    color: #565656;
    padding-bottom: 25px;
    border-bottom: 1px solid #e2e2e2;
  }
  .tbl-th li + li,
  .tbl-td li + li {
    margin-left: 35px;
  }
  .item-no {
    width: 67px;
    flex-shrink: 0;
  }
  .item-cat {
    width: 175px;
    flex-shrink: 0;
  }
  .item-tit {
    width: 100%;
    flex-shrink: 1;
  }
  .item-file {
    width: 175px;
    flex-shrink: 0;
  }
  .tbl-td li {
    display: flex;
    align-items: center;
    min-height: 90px;
    padding: 15px 15px 15px 0;
    font-size: 16px;
    color: #000;
    border-bottom: 1px solid #e2e2e2;
  }
  .item-tit.td-tit {
    font-size: 20px;
    letter-spacing: -1.2px;
    text-align: left;
  }
  .item-tit.td-tit a {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 600px;
    padding-right: 3px;
  }
  .item-tit.td-tit button {
    text-align: left;
  }
  .tbl-td.type-inquiry .item-tit button {
    text-align: left;
  }
  .tbl-td.type-inquiry .item-no,
  .tbl-th.type-inquiry .item-no {
    width: 39px;
    flex-shrink: 0;
  }
  .tbl-td.type-inquiry .item-cat,
  .tbl-th.type-inquiry .item-cat {
    width: 72px;
    flex-shrink: 0;
  }
  .tbl-td.type-inquiry .item-date,
  .tbl-th.type-inquiry .item-date {
    width: 135px;
    flex-shrink: 0;
  }
  .tbl-td.type-inquiry .item-reply,
  .tbl-th.type-inquiry .item-reply {
    width: 106px;
    flex-shrink: 0;
  }

  .answer-wrap {
    position: relative;
    /* padding: 31px 30px 36px 308px; */
    background-color: rgba(104, 201, 243, 0.1);
  }
  .answer-wrap .btn-close {
    position: absolute;
    top: 30px;
    right: 26px;
  }
  .answer-wrap .tbl-td li {
    height: auto;
    padding: 31px 60px 36px 0;
  }
  .answer-wrap .answer-txt {
    font-size: 16px;
    line-height: 26px;
    color: #565656;
    white-space: pre-wrap;
  }

  .answer-wrap.type-inquiry .tbl-td li {
    padding: 31px 60px 36px 45px;
  }
`
// 게시글 뷰
export const PostViewStyled = styled.div`
  .view-header .tit-wrap {
    padding-bottom: 26px;
    border-bottom: 1px solid #e2e2e2;
  }
  .view-header .tit-wrap .tit {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -1.2px;
  }
  .view-header .tit-wrap .notice {
    font-size: 16px;
    line-height: 33px;
    padding-right: 18.1px;
    vertical-align: top;
    color: #00abeb;
  }
  .detail-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
  }
  .detail-wrap .date {
    font-size: 14px;
    color: #565656;
  }
  .detail-wrap .file-wrap {
    display: flex;
    width: 400px;
    font-size: 14px;
    line-height: 26px;
    color: #565656;
  }
  .detail-wrap .file-wrap .txt {
    flex-shrink: 0;
  }
  .detail-wrap .file-wrap .file-list {
    margin-left: 20px;
  }
  .detail-wrap .file-wrap .file-list a,
  .detail-wrap .file-wrap .file-list button {
    display: flex;
    word-break: break-all;
    text-align: left;
  }
  .view-header + .view-content {
    margin-top: 56px;
  }
  .view-content .txt-wrap {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .btn-wrap {
    margin-top: 89px;
    display: flex;
    justify-content: flex-end;
  }
`

//버튼
export const BtnBorderSm = styled.button`
  min-width: 102px;
  height: 35px;
  padding: 0 33px;
  border-radius: 5px;
  border: solid 1px #a5a5a5;
  font-size: 16px;
  color: #565656;
  font-weight: 500;
`
export const BtnBorderMid = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 102px;
  height: 56px;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  font-size: 16px;
  color: #767676;
  .ico {
    width: 21px;
    height: 21px;
    margin-right: 12px;
  }
  .ico img {
    width: 100%;
    height: 100%;
  }
`

export const BtnBorderMidLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 102px;
  height: 56px;
  border-radius: 10px;
  border: solid 1px #c0c0c0;
  font-size: 16px;
  color: #767676;
  .ico {
    width: 21px;
    height: 21px;
    margin-right: 12px;
  }
  .ico img {
    width: 100%;
    height: 100%;
  }
`

export const BtnSolidMid = styled.button`
  min-width: 278px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #00abeb;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  :disabled {
    background-color: #e7e7e7;
    color: #767676;
    cursor: not-allowed;
  }
  &.fs-sm {
    font-size: 16px;
    font-weight: 400;
  }
`
export const BtnSolidGrayMid = styled.button`
  min-width: 278px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #f4f4f4;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  :disabled {
    background-color: #e7e7e7;
    color: #767676;
    cursor: not-allowed;
  }
  &.fs-sm {
    font-size: 16px;
    font-weight: 400;
  }
`

// 타이틀
export const PageTitStyled = styled.h3`
  margin-bottom: 74px;
  font-size: 46px;
  font-weight: 100;
  line-height: 1.3;
  letter-spacing: -2.3px;
  color: #000;
`
// 입력폼 큰 틀
export const FormWrapStyled = styled.div`
  display: flex;
  .tit-wrap {
    width: 277px;
    padding: 30px 30px 0 0;
    border-top: 1px solid #e2e2e2;
  }
  .tit-wrap .tit {
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -1.2px;
    color: #000;
  }
  .form-wrap {
    width: calc(100% - 307px);
    margin-left: 30px;
    padding: 30px 0 67px;
    border-top: 1px solid #e2e2e2;
  }
  &.last .tit-wrap,
  &.last .form-wrap {
    border-bottom: 1px solid #e2e2e2;
  }
  .inp-group + .inp-group {
    margin-top: 30px;
  }
  .inp-group .inp-tit {
    display: flex;
    font-size: 16px;
    line-height: 1.88;
    letter-spacing: -0.8px;
    color: #000;
    margin-bottom: 10px;
    align-items: center;
  }
  .inp-group .inp-tit .required {
    display: inline-block;
    margin-left: 5px;
    color: #ff5b14;
  }
  .inp-group .inp-tit .waring-txt {
    display: inline-block;
    margin-left: 10px;
    color: #ff3831;
  }
  .inp-group .inp {
    width: 100%;
  }

  .note-wrap .note {
    position: relative;
    margin-top: 8px;
    padding-left: 25px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.56;
    letter-spacing: -0.8px;
    color: #565656;
  }
  .note-wrap .note:before {
    position: absolute;
    top: 10px;
    left: 12px;
    width: 5px;
    height: 5px;
    background-color: #565656;
    border-radius: 50%;
    content: '';
  }
`
export const WhiteBoxStyled = styled.div`
  width: 520px;
  margin: auto;
  padding: 80px 60px 88px;
  background-color: #fff;
  .tit-wrap {
    margin-bottom: 40px;
  }
  .tit-wrap.mar-b0 {
    margin-bottom: 0;
  }
  .tit-wrap .tit {
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -1.4px;
    text-align: center;
    color: #000;

    b {
      font-weight: 500;
    }
  }
  .tit-wrap .sub-tit {
    width: 319px;
    height: 48px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: left;
    color: #000;
  }
  .explain-txt {
    padding: 40px 0;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: center;
    color: #000;
  }
  .form-wrap {
    margin-bottom: 20px;
  }
  .form-wrap > div + div {
    margin-top: 16px;
  }
  .form-wrap > div input {
    width: 100%;
  }
  .remember-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .remember-wrap .txt-link {
    font-size: 16px;
    color: #767676;
  }
  .err-txt-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 18px;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: #f6f7fa;
    white-space: pre-wrap;
  }
  .err-txt-wrap .err-txt {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    color: #ff3831;
  }
  .err-txt-inline {
    display: flex;
    margin-top: 22px;
  }
  .err-txt-inline .err-txt {
    font-size: 16px;
    letter-spacing: -0.8px;
    color: #ff3831;
    margin-left: 8px;
  }
  .login-btn-wrap {
    margin-top: 39px;
  }
  .login-btn-wrap button {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
  }
  .others-wrap {
    margin-top: 39px;
  }
  .border-txt span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1;
    letter-spacing: -0.8px;
    text-align: left;
    color: #a9a9a9;
  }
  .border-txt span::before,
  .border-txt span::after {
    display: block;
    width: 165px;
    height: 1px;
    background-color: #e2e2e2;
    content: '';
  }
  .others-btn-wrap {
    margin-top: 20px;
  }
  .others-btn-wrap button {
    width: 100%;
  }
  .others-btn-wrap button + button {
    margin-top: 16px;
  }
  .others-btn-wrap button + a {
    margin-top: 20px;
  }
  .go-join-wrap {
    margin-top: 40px;
    text-align: center;
    font-size: 16px;
  }
  .go-join-wrap .txt {
    color: #a9a9a9;
    margin-bottom: 10px;
  }
  .go-join-wrap .txt-link-underline {
    text-decoration: underline;
    color: #000;
  }

  .cmplt-box {
    display: flex;
    margin-top: 23px;
    align-items: center;
    justify-content: center;
    height: 166px;
    background-color: #f7f7f7;
  }
  .cmplt-box .txt {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.8px;
    text-align: center;
    color: #000;
  }
  .cmplt-box .txt span {
    font-weight: 500;
    color: #00abeb;
  }
`

export const DimmedStyled = styled.div`
  position: fixed;
  display: ${props => (props.isActive ? 'block' : 'none')};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 300;
  background-color: rgba(0, 0, 0, 0.5);
`
export const PaginationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-prev,
  .btn-next {
    display: flex;
    width: 32px;
    height: 32px;
  }
  .btn-prev {
    color: ${props => (props.prevActive ? '#000' : '#C8C8C8')};
  }
  .btn-next {
    color: ${props => (props.nextActive ? '#000' : '#C8C8C8')};
  }
  .pagination {
    display: flex;
    margin: 0 48px;
    align-items: center;
    justify-content: center;
  }
  .pagination li + li {
    margin-left: 27px;
  }
`
export const PageStyled = styled.button`
  font-size: 16px;
  font-weight: ${props => (props.active ? '500' : '300')};
  color: ${props => (props.active ? '#00abeb' : '#000')};
`
