import {combineReducers} from 'redux'
import ModalReducer from './modal'
import MenuReducer from './menu'
import CustomerMenuReducer from './customer'

const RootReducer = combineReducers({
  ModalReducer,
  MenuReducer,
  CustomerMenuReducer,
})

export default RootReducer
