import React, {Suspense} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

const loading = <></>

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

//컴포넌트
const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path='/*' name='Home' element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
